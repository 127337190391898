import * as React from "react"

import {
  Grid,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Typography,
} from '@mui/material';

import { navigate } from 'gatsby';

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import CONSTS from "../consts"


const NotFoundPage = ({
  
}) => {

    const pages = [{
        title: 'HTML Emails',
        location: CONSTS.PATHS.HTML_EMAIL_TEMPLATE_COLLECTION,
    },{
        title: 'Plain Text Emails',
        location: CONSTS.PATHS.PLANE_TEXT_EMAIL_TEMPLATE_COLLECTION,
    }];

  return (
    <Layout>
      <Box
        sx={{
          bgcolor: 'background.paper',
          backgroundColor: '#d7d7d7',
          backgroundImage: 'url("/html_email_and_text_email_header.jpg")',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#ffffffd4',
            pt: 8,
            pb: 6,
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              mb: 8,
            }}
          >
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                textAlign: 'center',
                fontSize: ['2.6rem', '4rem'],
              }}
            >
              Page Does Not Exist
            </Typography>
          </Container>
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: 'background.paper',
          px: 8,
          py: 6,
          backgroundColor: '#ededed',
        }}
      >
        <Typography
            variant="h1"
            sx={{
              fontWeight: '500',
              fontSize: '2rem',
              marginBottom: '10px'
            }}
        >
            Here are a few that do
        </Typography>
        <Grid container spacing={4}>
            {pages.map((page) => {
                return (
                    <Grid item xs={12} md={6}>
                        <Card sx={{ display: 'flex' }}>
                            <CardActionArea
                                onClick={() => {
                                    navigate(page.location)
                                }}
                            >
                                <CardContent sx={{ flex: 1 }}>
                                    {page.title}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
      </Box>
    
      

    </Layout>
  );
};

export const Head = () => <Seo title="Page not found" />

export default NotFoundPage
